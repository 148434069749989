
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  unref,
} from "vue";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import { gaEvent } from "@/utils/format";
// @ts-ignore
import iphone from '@/assets/img/iphone.svg';
// @ts-ignore
import Launcher from '@/assets/img/Launcher.svg';

export default defineComponent({
  name: "ChangeName",
  components: {},

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 480,
    },
    Okay: {
      type: String,
      default: "pop.changeBound",
    },
  },
  emits: ["cancle", "confirm"],
  setup(props, context) {
    const { t } = useI18n();
    const store = useStore();
    const visible = computed(() => props.visible);
    const expiredDevices = computed(() => {
      return store.state.expiredDevices;
    });

    // 表示当前选中id，来修改选中样式
    const pickOne = ref(null);
    const pickName = ref(null);
    const pickIos = ref(false);

    const handleClose = () => {
      context.emit("cancle", false);
    };

    const selectDevice = (id: any, name: any, isIos: boolean) => {
      pickOne.value = id;
      pickName.value = name;
      pickIos.value = isIos
      console.log('pickOne.value', pickOne.value, pickName.value, pickIos.value);
    };

    const submit = () => {
      console.log('');
      context.emit("confirm", pickOne.value, pickName.value, pickIos.value);
    };

    return {
      visible,
      pickOne,
      expiredDevices,
      submit,
      selectDevice,
      handleClose,

      iphone,
      Launcher
    };
  },
});
